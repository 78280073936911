import './style.scss';
import { Slider } from '../../../js/components/Slider/Slider';

export default !function () {
  const wrapper = document.querySelector('.reviews__slider-wrapper');

  if (!wrapper) {
    return;
  }

  const reviews = wrapper.querySelector('.reviews__list');
  const slider = wrapper.querySelector('.reviews__slider');
  const items = wrapper.querySelectorAll('.reviews__item');
  const controls = wrapper.querySelector('.reviews__controls');

  const options = {
    wrapper: slider,
    settings: {
      spaceBetween: 8,
      slideClass: 'reviews__item',
      wrapperClass: 'reviews__list',
      focusableElements: true,

      pagination: {
        el: '.reviews__current',
        type: 'fraction'
      },
      navigation: {
        nextEl: '.button-arrow--next',
        prevEl: '.button-arrow--prev'
      },

      breakpoints: {
        768: {
          spaceBetween: 24
        },
        1024: {
          spaceBetween: 32,
          slidesPerView: 4,
          centeredSlides: false
        }
      }
    },
    modules: ['pagination', 'navigation', 'a11y'],
    initCondition: {
      sm: items.length > 1,
      md: items.length > 2,
      lg: items.length > 4,
      xl: items.length > 4,
    },
    controls
  };

  new Slider(options).init();

  // Analytics
  reviews.addEventListener('click', (evt) => {
    const item = evt.target.closest('.reviews__item');

    if (item) {
      const number = [...reviews.children].indexOf(item) + 1;

      window.$analytics && window.$analytics.push('video-review-item-click', {
        number
      });
    }
  });
}();
